<template>
  <div class="echart" v-loading="loading">
    <div class="pie">
      <vue-echarts :option="options" style="height: 100%;" ref="chart" />
    </div>
  </div>
</template>
<script>
import { VueEcharts } from 'vue3-echarts'
import { getJobSummaryCompletedJobs } from '@/services/modules'

export default {
  name: "TotalJobOperationsPie",
  components: {
    VueEcharts,
  },
  props: {
    startDate: {
      type: String
    },
    endDate: {
      type: String
    },
    mock: {
      type: Boolean,
      default: false,
    }
  },
  async created() {
    await this.getData()
  },
  data() {
    return {
      loading: true,
      titleText: '',
      seriesData: [],
    };
  },
  methods: {
    async getData() {
      this.loading = true
      const res = await getJobSummaryCompletedJobs(this.startDate, this.endDate, this.mock)
      this.titleText = res?.totalJob ?? 0
      this.seriesData = [
        { value: res?.deliveryJob ?? 0, name: 'Delivery' },
        { value: res?.stsJob ?? 0, name: 'Ship-to-Ship' },
        { value: res?.loadingJob ?? 0, name: 'Loading' },
      ];
      this.loading = false
    }
  },
  computed: {
    options() {
      return {
        color: ['#193364', '#FA09B6', '#FDBC00'],
        textStyle: {
          fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif',
        },
        title: {
          text: this.titleText,
          subtext: 'Total Job Operations',
          left: 'left',
          show: true,
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          right: '10%',
          top: '55%',
          data: [
            'Delivery',
            'Ship-to-Ship',
            'Loading',
          ],
          show: true,
        },
        series: [
          {
            name: 'Total Job Operations',
            type: 'pie',
            radius: '60%',
            bottom: '5%',
            center: ['35%', '50%'],
            data: this.seriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }
    }
  }
}
</script>
<style scoped>
.echart {
  width: 100%;
  overflow-x: auto;
  scrollbar-width: thin;
}
.pie {
  height: 300px; 
  min-width: 400px;
  background-color: white; 
  border: 1px solid #E5E5E5; 
  border-radius: 10px; 
  padding: 10px;
}
</style>