<template>
  <div class="echart" v-loading="loading">
    <div class="bar">
      <vue-echarts :option="options" style="height: 100%;" ref="chart" />
    </div>
  </div>
</template>
<script>
import { VueEcharts } from 'vue3-echarts'
import { getJobSummaryOilTypeQuantities } from '@/services/modules'

export default {
  name: "TotalQuantityDeliveredBar",
  components: {
    VueEcharts,
  },
  props: {
    startDate: {
      type: String
    },
    endDate: {
      type: String
    },
    mock: {
      type: Boolean,
      default: false,
    }
  },
  async created() {
    await this.getData()
  },
  data() {
    return {
      loading: true,
      titleText: '',
      xAxisData: [],
      seriesData: [],
    };
  },
  methods: {
    async getData() {
      this.loading = true
      const res = await getJobSummaryOilTypeQuantities(this.startDate, this.endDate, this.mock);
      this.xAxisData = []
      this.seriesData = []
      let types = res?.types ?? []
      types.map(item => {
        this.xAxisData.push(item.formattedOilType)
        this.seriesData.push(item.quantity)
      })
      this.titleText = ( res?.total ?? 0 ) + ' MT'
      this.loading = false
    }
  },
  computed: {
    options() {
      return {
        textStyle: {
          fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif',
        },
        title: {
          text: this.titleText,
          subtext: 'Totol Quantity Delivered',
          left: 'left',
          show: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.xAxisData,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'Direct',
            data: this.seriesData,
            barWidth: '10%',
            type: 'bar',
            itemStyle: {
              color: function (param) {
                return '#FC8B50';
              }
            },
          }
        ]
      }
    }
  }
}
</script>
<style scoped>
.echart {
  width: 100%;
  overflow-x: auto;
  scrollbar-width: thin;
}
.bar {
  height: 300px; 
  min-width: 400px;
  background-color: white; 
  border: 1px solid #E5E5E5; 
  border-radius: 10px; 
  padding: 10px;
}
</style>