<template>
  <div class="manager-container">
    <div class="button-block">
      <div class="query-item">
        <el-select v-model="option" size="mini" @change="handleOnchange">
          <el-option v-for="item in options" 
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="query-item">
        <el-date-picker
          v-model="date"
          type="daterange"
          size="mini"
          value-format="yyyy-MM-dd"
          range-separator="-"
          @change="() => option = 'custom'"
          :start-placeholder="$t('startDate')"
          :end-placeholder="$t('endDate')">
        </el-date-picker>
      </div>
      <div class="query-item">
        <el-button type="primary" size="mini" @click="handleQuery">{{ $t('query') }}</el-button>
      </div>
    </div>
    <el-row :gutter="10">
      <el-col :span="24" :lg="12" style="padding-bottom: 10px;">
        <TotalJobOperationsPie :key="componentKey" :startDate="startDate" :endDate="endDate" :mock="mock" />
      </el-col>
      <el-col :span="24" :lg="12" style="padding-bottom: 10px;">
        <NoteOfProtestsIssued :key="componentKey" :startDate="startDate" :endDate="endDate" :mock="mock" />
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="24" style="padding-bottom: 10px;">
        <TotalQuantityDeliveredBar :key="componentKey" :startDate="startDate" :endDate="endDate" :mock="mock" />
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="24" :lg="12" style="padding-bottom: 10px;">
        <TotalVesselDeliveredBar :key="componentKey" :startDate="startDate" :endDate="endDate" :mock="mock" />
      </el-col>
      <el-col :span="24" :lg="12" style="padding-bottom: 10px;">
        <TotalBargesLoadedBar :key="componentKey" :startDate="startDate" :endDate="endDate" :mock="mock" />
      </el-col>
    </el-row>
  </div>
</template>
<script>
import TotalJobOperationsPie from "./components/totalJobOperationsPie.vue"
import TotalQuantityDeliveredBar from "./components/totalQuantityDeliveredBar.vue"
import TotalVesselDeliveredBar from "./components/totalVesselDeliveredBar.vue"
import TotalBargesLoadedBar from "./components/totalBargesLoadedBar.vue"
import NoteOfProtestsIssued from "./components/noteOfProtestsIssued.vue"
import moment from "moment"

export default {
  name: "Dashboard",
  components: {
    TotalJobOperationsPie,
    TotalQuantityDeliveredBar,
    TotalVesselDeliveredBar,
    TotalBargesLoadedBar,
    NoteOfProtestsIssued,
  },
  data() {
    return {
      componentKey: 0,
      option: 'month',
      mock: false,
      options: [
        { label: 'Week', value: 'week' },
        { label: 'Month', value: 'month' },
        { label: 'Year', value: 'year' },
        { label: 'Set A Date', value: 'custom' },
      ],
      date: null,
      startDate: "", 
      endDate: "",
      sortOrder: "desc",
    }
  },
  async created() {
    this.handleOnchange()
    await this.handleQuery()
  },
  methods: {
    handleOnchange() {
      let date1 = moment().format('YYYY-MM-DD')
      let date2 = moment().format('YYYY-MM-DD')
      if(this.option != 'custom') {
        date1 = moment().startOf(this.option).format('YYYY-MM-DD')
        date2 = moment().endOf(this.option).format('YYYY-MM-DD')
      }
      this.date = [ date1, date2 ]
    },
    async handleQuery() {
      this.startDate = moment(this.date[0]).add('8', 'Hours').toISOString()
      this.endDate = moment(this.date[1]).endOf('day').add('8', 'Hours').toISOString()
      this.componentKey += 1
    },
  }
}
</script>
<style lang="scss" scoped>
.manager-container {
  height: 100%; 
  overflow-x: hidden;
  overflow-y: auto;

  .button-block {
    box-sizing: border-box;
    width: 100%;

    .query-item {
      display: inline-block;
      padding-right: 10px;
      padding-bottom: 10px;
    }
  }
}
</style>