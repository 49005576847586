var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "echart",
    },
    [
      _c("div", { staticClass: "notes" }, [
        _c("div", { staticClass: "total" }, [_vm._v(_vm._s(_vm.total))]),
        _c("div", { staticClass: "title" }, [
          _vm._v("Note Of Protests Issued"),
        ]),
        _c("div", { staticClass: "items" }, [
          _c("div", { staticClass: "item" }, [
            _c("div", [_vm._v(_vm._s(_vm.barge))]),
            _c("h5", [_vm._v("Barge")]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", [_vm._v(_vm._s(_vm.vessel))]),
            _c("h5", [_vm._v("Vessel")]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", [_vm._v(_vm._s(_vm.others))]),
            _c("h5", [_vm._v("Others")]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }