var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "manager-container" },
    [
      _c("div", { staticClass: "button-block" }, [
        _c(
          "div",
          { staticClass: "query-item" },
          [
            _c(
              "el-select",
              {
                attrs: { size: "mini" },
                on: { change: _vm.handleOnchange },
                model: {
                  value: _vm.option,
                  callback: function ($$v) {
                    _vm.option = $$v
                  },
                  expression: "option",
                },
              },
              _vm._l(_vm.options, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "query-item" },
          [
            _c("el-date-picker", {
              attrs: {
                type: "daterange",
                size: "mini",
                "value-format": "yyyy-MM-dd",
                "range-separator": "-",
                "start-placeholder": _vm.$t("startDate"),
                "end-placeholder": _vm.$t("endDate"),
              },
              on: { change: () => (_vm.option = "custom") },
              model: {
                value: _vm.date,
                callback: function ($$v) {
                  _vm.date = $$v
                },
                expression: "date",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "query-item" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.handleQuery },
              },
              [_vm._v(_vm._s(_vm.$t("query")))]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            {
              staticStyle: { "padding-bottom": "10px" },
              attrs: { span: 24, lg: 12 },
            },
            [
              _c("TotalJobOperationsPie", {
                key: _vm.componentKey,
                attrs: {
                  startDate: _vm.startDate,
                  endDate: _vm.endDate,
                  mock: _vm.mock,
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticStyle: { "padding-bottom": "10px" },
              attrs: { span: 24, lg: 12 },
            },
            [
              _c("NoteOfProtestsIssued", {
                key: _vm.componentKey,
                attrs: {
                  startDate: _vm.startDate,
                  endDate: _vm.endDate,
                  mock: _vm.mock,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { staticStyle: { "padding-bottom": "10px" }, attrs: { span: 24 } },
            [
              _c("TotalQuantityDeliveredBar", {
                key: _vm.componentKey,
                attrs: {
                  startDate: _vm.startDate,
                  endDate: _vm.endDate,
                  mock: _vm.mock,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            {
              staticStyle: { "padding-bottom": "10px" },
              attrs: { span: 24, lg: 12 },
            },
            [
              _c("TotalVesselDeliveredBar", {
                key: _vm.componentKey,
                attrs: {
                  startDate: _vm.startDate,
                  endDate: _vm.endDate,
                  mock: _vm.mock,
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticStyle: { "padding-bottom": "10px" },
              attrs: { span: 24, lg: 12 },
            },
            [
              _c("TotalBargesLoadedBar", {
                key: _vm.componentKey,
                attrs: {
                  startDate: _vm.startDate,
                  endDate: _vm.endDate,
                  mock: _vm.mock,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }